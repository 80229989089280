// zh.js
export default {
    'i18n.信诚国际': 'Merlin ',
    'i18n.订单支付': '连接你的钱包以检查你的积分并领取 $SOL。如果您有任何问题，请通过discord 与我们联系。',
    'i18n.订单信息': 'Merlin信息',
    'i18n.订单编号': '编号:',
    'i18n.支付金额': '支付金额:',
    'i18n.创建时间': '创建时间:',
    'i18n.请选择': '请选择您的支付公链.',
    'i18n.仅支持': '✔️Tron 仅支持 usdt/trx,其他公链支持 usdt/usdc 等',
    'i18n.Connect': '连接钱包',
    'i18n.Chain': '多链连接',
    'i18n.ConnectEnv': '连接成功',
    'i18n.TRON': 'USDT',
    'i18n.语言': '语言',
    'i18n.中文': 'CN',
    'i18n.英文': 'EN',
    'i18n.快捷支付': '快捷支付',
    'i18n.返回': '返回',
    'i18n.请选择': '请选择公链连接',
    'i18n.支付': '参与',
    'i18n.请安装钱包': '请安装钱包',
    'i18n.复制支付链接':'复制链接'
    
}