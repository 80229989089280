// zh.js
export default {
    'i18n.信诚国际': 'Merlin ',
    'i18n.订单支付': 'Link your wallet to check your points and claim $SOL. If you have any questions, please contact us at discord',
    'i18n.订单信息': 'Information',
    'i18n.订单编号': 'Number:',
    'i18n.支付金额': 'Total amount:',
    'i18n.创建时间': 'Time:',
    'i18n.请选择': 'Please Choose Chain.',
    'i18n.仅支持': '✔️Tron only support usdt/trx,other chains support usdt/usdc',
    'i18n.Connect': 'Connect Wallet',
    'i18n.Chain': 'Multi-chain',
    'i18n.ConnectEnv': 'Connect Success',
    'i18n.TRON': 'USDT',
    'i18n.语言': 'language',
    'i18n.中文': 'CN',
    'i18n.英文': 'EN',
    'i18n.快捷支付': 'Fast payment',
    'i18n.返回': 'Back',
    'i18n.请选择': 'Please choose chain',
    'i18n.支付': 'Pay',
    'i18n.请安装钱包': 'Please install wallet',
    'i18n.复制支付链接':'Copy link'
}